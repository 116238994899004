import React from 'react';
import './ClaimBanner.css'; // Make sure you have the corresponding CSS file

const ClaimBanner = ({ message, message2, buttonText }) => {
  const handleLearnMoreClick = () => {
    window.open('https://members.theproducthub.co/SignIn', '_blank');
  };

  return (
    <div className="claim-banner">
      <div className="claim-banner-content">
        <div>
          <p className="claim-message">{message}</p>
          <p className="claim-message2">{message2}</p>
        </div>
        <button className="claim-button" onClick={handleLearnMoreClick}>
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default ClaimBanner;

