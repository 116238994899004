import React from 'react';
import addressImage from '../assets/address.png';
import phoneImage from '../assets/phone.png';
import emailImage from '../assets/email.png';
import './ContactUs.css';

const ContactUsPage = () => {
    return (
        <div className="container">
            <section className="contact-section">
                <h2>Contact Us</h2>
                <div className="contact-info">
                    <div className="contact-item">
                        <div className="icon-container">
                            <img src={addressImage} alt="Address" className='icon'/>
                        </div>  
                        <p>Suite 4, Level 1, 157 Frome Street<br />Adelaide, SA 5000<br />AUSTRALIA</p>
                    </div>
                    <div className="contact-item">
                        <div className="icon-container">
                            <img src={phoneImage} alt="Phone" className="icon" />
                        </div>
                        <p><a href="tel:+613002663287">1300 663 287</a></p>
                    </div>
                    <div className="contact-item">
                        <div className="icon-container">
                            <img src={emailImage} alt="Email" className="icon" />
                        </div>
                        <p><a href="mailto:info@producthub.co">info@producthub.co</a></p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default ContactUsPage;
