import React, { useState, useEffect, useRef } from 'react';
import './SearchBox.css';
import { useNavigate } from 'react-router-dom';
import searchIcon from '../assets/Search.png';
import logoImage from '../assets/PH_Logo.png';

const SearchBox = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const inputRef = useRef(null);

    useEffect(() => {
        // Focus the input element when the component mounts
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const handleSearch = () => {
        navigate('/searchResult');
    };

    const handleSignIn = () => {
        window.location.href = 'https://members.theproducthub.co/SignIn';
    };

    //just for testing
    return (
        <div className="search-box-page">
            <header className="header">
                <a href="/about" className="about-link">About</a>
                {/* <img src={profileImage} alt="Profile" className="profile-image" /> */}
                <button className="sign-in-button" onClick={handleSignIn}>
                    Sign In
                </button>
            </header>
            <div className="logo-container">
                <img src={logoImage} alt="Logo" className="logo" />
            </div>
            <div className="search-box">
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    ref={inputRef} // Set the ref to the input element
                />
                <button onClick={handleSearch}>
                    <img src={searchIcon} alt="Search" />
                </button>
            </div>
        </div>
    );
};

export default SearchBox;
