import React, { useState,useEffect,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './SearchToolbar.css';  // Import the CSS for styling
import logoImage from '../assets/PH_Logo.png';
import searchIcon from '../assets/Search.png';

const SearchToolbar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const handleSubmit = (e) => {
    navigate('/');  // Redirect to the home page
  };

  useEffect(() => {
    // Focus the input element when the component mounts
    if (inputRef.current) {
        inputRef.current.focus();
    }
}, []);

  return (
    <div className="search-toolbar">
      {/* <div className="logo-container">
          <img src={logoImage} alt="Logo" className="logo" />
      </div> */}
      <img src={logoImage} alt="Logo" className="search-logo" />
      <div className="search-box">
        <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            ref={inputRef} // Set the ref to the input element
        />
        <button onClick={handleSubmit}>
            <img src={searchIcon} alt="Search" />
        </button>
      </div>
    </div>
  );
};

export default SearchToolbar;
