// AboutPage.jsx

import React from 'react';
import './About.css';
import { Link } from 'react-router-dom';
import linkedinIcon from '../assets/linkedin.png';
import facebookIcon from '../assets/facebook.png';

const AboutPage = () => {
    return (
        <div className="container">
            <Header />
            <WhatIsProductHubSection />
            <WhyChooseProductHubSection />
            <HowProductHubWorksSection />
            <WhoUsesProductHubSection />
            <ProductHubPartnershipsSection />
            <ProductHubUSPartnershipsSection />
            <WeeklyOnlineSessionSection />
            <div className="follow-us-section">
                <p>Follow Us</p>
                <div className="follow-us-icons">
                    <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                        <img src={linkedinIcon} alt="LinkedIn" />
                    </a>
                    <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                        <img src={facebookIcon} alt="Facebook" />
                    </a>
                </div>
            </div>
            <Footer />
            <div className="footer-bottom">
                <div className="footer-logo">ProductHub</div>
                <Link to="/privacy">Privacy</Link>
                <Link to="/terms">Terms</Link>
            
            </div>
        </div>
    );
}

const Header = () => {
    return (
        <section className="section product-hub-section">
            <div className="container">
                <h2>Welcome to ProductHub – Empowering Your Product Data Journey</h2>
                <p>
                    In the fast-paced world of commerce, precise product data is the key to success.
                    ProductHub is your strategic partner, providing a streamlined platform to view,
                    analyse, and update your product information effortlessly.
                </p>
            </div>
        </section>
    );
}

const WhatIsProductHubSection = () => {
    return (
        <section className="section product-hub-section">
            <div className="container">
                <h2>What is ProductHub?</h2>
                <p>
                    ProductHub is your dynamic solution for streamlined product data management. At its core, ProductHub is a centralized platform meticulously crafted to simplify and elevate how businesses handle their product information. Through an intuitive interface and user-friendly tabs, ProductHub empowers users across the supply chain to effortlessly view, analyse, and update their product data. 
                </p>
                <p>
                    From optimizing the supply chain with seamless data flow to enhancing sales and marketing efforts, ProductHub is more than just a platform; it's a strategic partner in your journey towards efficient product information management. With a focus on sustainability, compliance with global standards, and the flexibility to export data to various platforms, ProductHub ensures precision, consistency, and excellence in your product data journey. 
                </p>
            </div>
        </section>
    );
}

const WhyChooseProductHubSection = () => {
    return (
        <section className="section why-choose-section">
            <div className="container">
                <h2>Why Choose ProductHub?</h2>
                
                    <h3>Efficiency Across the Supply Chain</h3>
                    <p>ProductHub enhances every step of your supply chain, from manufacturing to distribution and retail. Our platform ensures that accurate and timely information flows seamlessly through the entire process. </p>
                    <h3>Elevate Your Sales and Marketing</h3>
                    <p>Unleash the potential of your products in the market. ProductHub not only organizes your data but also provides tools to refine your product presentation, making your offerings more compelling to customers and retailers alike. </p>
                    <h3>Data-Driven Logistics</h3>
                    <p>Simplify logistics with precise data. From manufacturing to distributors and wholesalers, accurate measurements and sizing details in the platform facilitate efficient storage, transportation, and sales space allocation. </p>
                    <h3>Sustainability at the Core</h3>
                    <p>The "Packaging & Sustainability" tab allows you to showcase your commitment to sustainable practices. Detail packaging information, recyclability, and returnability, aligning with the growing consumer demand for eco-friendly choices. </p>
                    <h3>Global Standards Compliance</h3>
                    <p>As an accredited partner in GS1 Australia's Alliance Partnership Program, ProductHub ensures that your product data aligns seamlessly with international standards. We empower you to maintain data consistency across GS1 databases worldwide. </p>
                    <h3>Export Functionality for Maximum Flexibility</h3>
                    <p>ProductHub not only supports the Bring Back Australia app but also enables the export of your data to GS1 platforms and other systems. This flexibility ensures that your consistent and accurate product information reaches any platform you use. </p>
                <a href='https://members.theproducthub.co/SignUp' className="cta-button">Take Control of Your Product Data – Sign Up!</a>
            </div>
        </section>
    );
}

const HowProductHubWorksSection = () => {
    return (
        <section className="section how-it-works-section">
            <div className="container">
                <h2>How ProductHub Works for Suppliers: Your Data Empowerment Platform </h2>
                <p>
                    Welcome to ProductHub, your essential partner in elevating product data management.
                    For suppliers, our platform simplifies the process of entering and maintaining data,
                    transforming it into a valuable asset for your business.
                </p>
                <h3>Effortless Data Entry:</h3>
                <p>ProductHub provides an intuitive and user-friendly interface, ensuring a seamless data entry experience. Whether you're starting from barcode-based information or enhancing existing data from GS1 sources, the platform empowers you to effortlessly manage every detail of your products. </p>
                <h3>Unlocking Collaboration Opportunities:</h3>
                <p>By joining ProductHub, you're not just managing data; you're participating in a collaborative ecosystem. Share your product information with wholesalers, distributors, retailers, and even end consumers. This transparency fosters trust, opens new distribution channels, and positions your products for increased visibility.  </p>
                <h3>Maximizing Industry Impact:</h3>
                <p>As a supplier, your data is a key driver of industry impact. By contributing to ProductHub, you're part of a network that goes beyond individual businesses. The enhanced information becomes a catalyst for industry growth, influencing decision-makers, and shaping the marketplace.  </p>
                <h3>Seamless Integration and Export: </h3>
                <p>ProductHub ensures flexibility in managing your data. Effortlessly export refined information back to GS1 for broader industry alignment. Integrate it into your own systems, whether for website updates, product catalogues, or other platforms. This streamlined approach guarantees consistency and accuracy across all touchpoints. 
                   Join ProductHub and embark on a journey where entering and sharing data becomes a catalyst for your business success. Empower your products with visibility, transparency, and the collaborative strength of a thriving industry network. 
                </p>
            </div>
        </section>
    );
}

const WhoUsesProductHubSection = () => {
    return (
        <section className="section who-uses-section">
            <div className="container">
                <h2>Who Uses ProductHub?</h2>
                <p>ProductHub serves a diverse range of users across the supply chain. Whether you're a supplier, distributor, wholesaler, retailer, or consumer, our platform provides tailored benefits to meet your needs. </p>
                <h3>Suppliers & Manufacturers: </h3>
                <ul>
                    <li>Streamlined Data Entry: Effortlessly enter and maintain comprehensive product data. </li>
                    <li>Global Visibility: Showcase products with multilingual descriptions for a global audience. </li>
                    <li>Collaboration Opportunities: Share information with wholesalers, distributors, and retailers. </li>
                    <li>Industry Impact: Contribute to an industry network, influencing decision-makers. </li>
                    <li>Flexible Integration: Seamlessly export data back to GS1 or integrate into your own systems. </li>
                    <li>Increased Visibility: Position products for increased visibility and new distribution channels. </li>
                    <li>Trusted Transparency: Build trust through transparent sharing with industry partners. </li>
                </ul>
                <h3>Distributors & Wholesalers: </h3>
                <ul>
                    <li>Access Comprehensive Data: Gain access to detailed product information from suppliers. </li>
                    <li>Informed Decision-Making: Make informed decisions based on accurate and up-to-date data.  </li>
                    <li>Streamlined Ordering: Optimize product ordering processes with precise information.  </li>
                    <li>Improved Collaboration: Collaborate seamlessly with suppliers for better inventory management.  </li>
                    <li>Enhanced Visibility: Increase visibility and range products effectively for retailers.  </li>
                </ul>
                <h3>Logistics & Warehousing:  </h3>
                <ul>
                    <li>Efficient Storage Planning: Utilize precise measurement and sizing data for efficient storage. </li>
                    <li>Streamlined Logistics: Optimize logistics and freight planning based on accurate product details.  </li>
                    <li>Improved Accuracy: Ensure accurate handling and transportation with detailed product information. </li>
                    <li>Enhanced Planning: Access real-time product availability and lifecycle information.   </li>
                    <li>Streamlined Processes: Efficiently manage products through the supply chain with comprehensive data.   </li>
                </ul>
                <h3>Retailers:</h3>
                <ul>
                    <li>Accurate Product Information: Receive accurate and detailed product information for listing. </li>
                    <li>Efficient Inventory Management: Streamline inventory management with precise data. </li>
                    <li>Better Product Ranging: Improve product ranging by accessing detailed categorization.  </li>
                    <li>Streamlined Orders: Simplify order processes with clear product availability information.  </li>
                    <li>Enhanced Consumer Trust: Build consumer trust through transparent and accurate product details.  </li>
                </ul>
                <h3>Consumers: </h3>
                <ul>
                    <li>Detailed Product Information: Access detailed information for informed purchasing decisions. </li>
                    <li>Variety in Product Images: Enjoy a comprehensive view with multiple product images (future feature). </li>
                    <li>Sustainability Information: Make eco-conscious choices with detailed packaging and sustainability data. </li>
                    <li>Accurate Product Availability: Stay informed about product availability and release dates.  </li>
                    <li>Trusted Purchases: Purchase with confidence, knowing the information is accurate and up-to-date. </li>
                </ul>
            </div>
        </section>
    );
}

const ProductHubPartnershipsSection = () => {
    return (
        <section className="section partnerships-section">
            <div className="container">
                <h2>The ProductHub and GS1 Australia Partnership</h2>
                <p>
                ProductHub is proud to be an accredited partner in GS1 Australia's Alliance Partnership Program. This prestigious recognition highlights our expertise and dedication to maintaining high standards in product data management. As part of this partnership, we are committed to helping businesses align their product data with GS1 standards, ensuring accuracy, consistency, and global compliance. Our collaboration with GS1 Australia reinforces our mission to provide top-tier solutions that support the entire supply chain. 
                </p>
                <a href={'https://members.theproducthub.co/SignUp'} className="cta-button">Elevate Your Product Information – Get Started Now!</a>
            </div>
        </section>
    );
}

const ProductHubUSPartnershipsSection = () => {
    return (
        <section className="section partnerships-section">
            <div className="container">
                <h2>The ProductHub and GS1 United States Partnership </h2>
                <p>
                    We are also honored to be an Executive Solution Partner with GS1 USA. This esteemed partnership signifies our deep commitment to excellence in product data management within the United States. Through this collaboration, ProductHub leverages GS1 USA's extensive resources and standards to offer our users unparalleled support and integration capabilities. This relationship underscores our position as a trusted leader in the industry, ensuring that our platform not only meets but exceeds the rigorous requirements set by GS1 USA. 
                </p>
                <a href='https://members.theproducthub.co/SignUp' className="cta-button">Elevate Your Product Information – Get Started Now!</a>
            </div>
        </section>
    );
}

const WeeklyOnlineSessionSection = () => {
    return (
        <section className="section weekly-session-section">
            <div className="container">
                <h2>Weekly Online Group Information Session</h2>
                <p>
                    Do you have questions or need more information before joining ProductHub? Join our weekly online group information session with Q&A. This interactive session is designed to provide you with all the details you need about ProductHub, how it works, and how it can benefit your business. Our experts will be on hand to answer any questions you may have and guide you through the process. 
                </p>
                <a href='https://members.theproducthub.co/SignUp' className="cta-button">Link to Webinar</a>
            </div>
        </section>
    );
}

const Footer = () => {
    return (
        <footer className="footer">
        <div className="container"> 
            <nav>
                <ul className="nav-column">
                    <li><Link to="/about">About Us</Link></li>
                    <li><Link to="/contactUs">Contact Us</Link></li>
                    <li><Link to="/gs1-australia-alliance-partnership">GS1 Australia Alliance Partnership</Link></li>
                    <li><Link to="/gs1-us-executive-solution-partnership">GS1 US Executive Solution Partnership</Link></li>
                    <li><Link to="/faq">FAQ</Link></li>
                </ul>
            </nav>
        </div>
    </footer>
    );
}

export default AboutPage;
