import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { fetchCompanyData, fetchProductData } from '../api';
import './ResultPage.css';  // Import the CSS for styling
import defaultImage from '../assets/GenericProductSquare2.jpeg';
import ClaimBanner from './ClaimBanner';
import SearchToolbar from '../components/SearchToolbar';

// Component to display the result page
const ResultPage = () => {
  const location = useLocation();
  const [companyData, setCompanyData] = useState(null);
  const [productData, setProductData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const t = params.get('t');
    const d = params.get('d');
    const n = params.get('n');

    if (t === 'C') {
      fetchCompanyData(d, n).then(data => {
        setCompanyData(data);
        setProductData(null); // Clear any existing product data
        setIsLoading(false);
      });
    } else if (t === 'P') {
        fetchProductData(d, n).then(data => {
            setProductData(data);
            setCompanyData(null); // Clear any existing company data
            setIsLoading(false);
          });
    }
  }, [location.search]);

  return (
    <>
    <div>
        <SearchToolbar />
    </div>
    <div className="result-page">
      {isLoading && <p className="loading">Loading...</p>}
      {companyData && (
        <>
            <ClaimBanner
                message={`${companyData.CompanyName} is an unclaimed page.`}
                message2={`Only a current employee of "${companyData.CompanyName}" may claim this page. Know someone who can claim this page?`}
                buttonText="Claim this company"
            />
            <div className="company-info">
                <div className="company-info-header">
                    <div className="company-image-box">
                        <img src={companyData.CompanyImageURL || defaultImage} alt={`${companyData.CompanyName} logo`} />
                    </div>
                <table className="info-table">
                    <tr>
                        <th className='brand'>CompanyName:</th>
                        <td className='brand'>{companyData.CompanyName}</td>
                    </tr>
                    <h2>Company Detail</h2>
                    <tbody>
                      {[
                        ['CompanyID', companyData.CompanyID],
                        ['Status', companyData.Status],
                        ['DataSource', companyData.DataSource],
                        ['FirstScan', companyData.FK_FirstScan],
                        ['ParentCompany', companyData.FK_Parent],
                        ['UserControlled', companyData.UserControlled],
                        ['EntityGLN', companyData.EntityGLN],
                        ['GSRN', companyData.GSRN],
                        ['GTIN GS1', companyData.GTIN_GS1],
                        ['GTIN Non-GS1', companyData.GTIN_NonGS1],
                        ['ISIN', companyData.ISIN],
                        ['Registered Name', companyData.RegisteredName],
                        ['Company Type', companyData.CompanyType],
                        ['Tax Country', companyData.TaxCountry],
                        ['Tax Number', companyData.TaxNumber],
                        ['Tax Type', companyData.TaxType],
                        ['Main Industry', companyData.MainIndustry],
                        ['Phone Company', companyData.PhoneCompany],
                        ['Email Info', companyData.EmailInfo],
                        ['Email Sales', companyData.EmailSales],
                        ['Website', companyData.Website],
                        ['Online Store URL', companyData.OnlineStoreURL],
                        ['Address', `${companyData.AddressStreet1} ${companyData.AddressStreet2} ${companyData.AddressStreet3}, ${companyData.AddressCity}, ${companyData.AddressState} ${companyData.AddressPostCode}, ${companyData.AddressCountry}`],
                        ['Comments', companyData.Comments],
                        ['Date Created', companyData.DateCreated],
                        ['Last Update', companyData.LastUpdate],
                      ].map(([fieldName, fieldValue]) => (
                        <tr key={fieldName}>
                          <th>{fieldName}:</th>
                          <td>{fieldValue}</td>
                        </tr>
                      ))}
                    </tbody>
                </table>
            </div>  
            </div>
        </>
      )}

      {productData && (
        <>
            <ClaimBanner
                message={`${productData.Brand} - ${productData.FunctionalName} is an unclaimed page.`}
                message2={`Only a current employee of "${productData.CompanyName}" may claim this page. Know someone who can claim this page?`}
                buttonText="Claim this product"
            />
            <div className="product-info">
                <div className="product-info-header">
                    <div className="product-image-box">
                        <img src={productData.ProductImageURL || defaultImage} alt="Product" />
                    </div>
                    <table className="info-table">
                        <tr>
                            <th className='brand'>Brand:</th>
                            <td className='brand'>{productData.Brand} - {productData.FunctionalName}</td>
                        </tr>
                        <tr>
                            <th className='brand'>Description:</th>
                            <td className='brand'>{productData.TradeItemDesc}</td>
                        </tr>
                        <h2>Product Detail</h2>
                        <tbody>
                        {[
                            ['ProductID', productData.ProductID],
                            ['Status', productData.Status],
                            ['DataSource', productData.DataSource],
                            ['GTIN', productData.GTIN],
                            ['Brand Sub', productData.BrandSub],
                            ['Net Content', `${productData.NetContent} ${productData.NetContentUOM}`],
                            ['Manufacturer Internal Reference', productData.ManufacturerInternalReference],
                            ['Country Of Origin', productData.CountryOfOrigin],
                            ['Height', `${productData.Height} ${productData.HeightUOM}`],
                            ['Width', `${productData.Width} ${productData.WidthUOM}`],
                            ['Depth', `${productData.Depth} ${productData.DepthUOM}`],
                            ['Weight', `${productData.Weight} ${productData.WeightUOM}`],
                            ['Pack Material Description', productData.PackMaterialDesc],
                            ['Pack Material Type', productData.PackMaterialType],
                            ['Pack Material Returnable', productData.PackMaterialReturnable],
                            ['Labelling Additional Phrase', productData.LabellingAdditionalPhrase],
                            ['Labelling Country Of Origin', productData.LabellingCountryOfOrigin],
                            ['Labelling Ingredient Statement', productData.LabellingIngredientStatement],
                            ['Labelling Packed Statement', productData.LabellingPackedStatement],
                            ['Labelling Australian Percent', productData.LabellingAustralianContentPercent],
                            ['Labelling Product Name', productData.LabellingProductName],
                            ['Labelling Get Details', productData.LabellingGetDetails],
                            ['Web Document Number', productData.WebDocumentNumber],
                            ['Web Document ID', productData.FK_WebDocumentID],
                            ['Date Created', productData.DateCreated],
                            ['Last Update', productData.LastUpdate],
                        ].map(([fieldName, fieldValue]) => (
                            <tr key={fieldName}>
                            <th>{fieldName}:</th>
                            <td>{fieldValue}</td>
                            </tr>
                        ))}
                        <h2>Company Detail</h2>
                            <tr>
                                <th>Company Name:</th>
                                <td>
                                    <a href={`https://public.test-theproducthub.co/search?t=C&d=${productData.CompanyWebDocumentName}&n=${productData.CompanyWebDocumentNumber}`}>
                                        {productData.CompanyName}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <th>Address:</th>
                                <td>{productData.AddressCity}, {productData.AddressState} {productData.AddressPostCode}, {productData.AddressCountry}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>  
            </div>
        </>
      )}
    </div>
    </>
  );
};

export default ResultPage;

    

