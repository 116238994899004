import React from 'react';
import './ComingSoon.css';

const ComingSoon = () => {
  return (
    <div className="coming-soon-page">
      <h1>Coming Soon</h1>
      <p>We are working hard to bring you this feature. Stay tuned!</p>
    </div>
  );
};

export default ComingSoon;

