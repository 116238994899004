// Function to fetch company data using POST request
export const fetchCompanyData = async (documentName, webDocumentNumber) => {
    // Make a POST request to the companyView endpoint with documentName and webDocumentNumber in the request body
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}Public/companyView`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ DocumentName: documentName, WebDocumentNumber: webDocumentNumber })  // Send documentName and webDocumentNumber as JSON in the request body
    });
    // Parse the JSON response and return it
    return response.json();
  };
  
  // Function to fetch product data using POST request
  export const fetchProductData = async (documentName, webDocumentNumber) => {
    // Make a POST request to the productView endpoint with documentName and webDocumentNumber in the request body
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}Public/productView`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ DocumentName: documentName, WebDocumentNumber: webDocumentNumber })  // Send documentName and webDocumentNumber as JSON in the request body
    });
    // Parse the JSON response and return it
    return response.json();
  };
  
  